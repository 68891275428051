import React, { useState, useEffect, useRef, useCallback } from 'react';
import Helmet from 'react-helmet';
import styled, { css } from 'styled-components';
import { useSpring, animated, config } from 'react-spring';
import YouTubeEmbed from './YouTubeEmbed';
import Video from './Video';
import Picture from './Picture';
import { assetIndex, focusedAssetIndex } from '../utils/indexes';
import UnMagnifySVG from '../images/unmagnify.svg';
import MagnifySVG from '../images/magnify.svg';

const ZoomableAsset = ({
  type,
  captionPlaintext,
  video,
  youTubeVideo,
  hasSound,
  image,
  singleSource,
  disableZoom,
  hideControls,
  background,
  chrome,
}) => {
  const [assetFocusable, setAssetFocusable] = useState();
  const [assetFocused, setAssetFocused] = useState(false);
  const [zIndex, setZIndex] = useState(assetIndex);
  const asset = useRef();

  const [transformStyle, setTransformStyle] = useSpring(() => ({
    from: { xys: [0, 0, 1] },
    config: { config: config.stiff, clamp: true },
    onFrame: ({ xys }) =>
      setZIndex(xys[2] > 1 ? focusedAssetIndex : assetIndex),
  }));

  const getAssetTransform = () => {
    const assetRect = asset.current.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const horizontalOffset =
      (windowWidth - assetRect.width) / 2 - assetRect.left;
    const verticalOffset =
      (windowHeight - assetRect.height) / 2 - assetRect.top;

    const scaleToFullWidth = windowWidth / assetRect.width;
    const scaleToFullHeight = windowHeight / assetRect.height;
    const scale = Math.min(scaleToFullHeight, scaleToFullWidth);

    return scale > 1.1 && [horizontalOffset, verticalOffset, scale];
  };

  const unfocusAsset = useCallback(() => {
    setTransformStyle({ xys: [0, 0, 1] });
    setAssetFocused(false);
  }, []);

  const testAssetFocusable = () => {
    if (assetFocused || disableZoom) return;

    const newAssetTransform = getAssetTransform();
    setAssetFocusable(!!newAssetTransform);
  };

  const tryFocusAsset = () => {
    if (assetFocused || disableZoom) return;

    const newAssetTransform = getAssetTransform();

    if (newAssetTransform) {
      document.addEventListener('scroll', unfocusAsset, { once: true });
      document.addEventListener('click', unfocusAsset, { once: true });

      setAssetFocused(true);
      setTransformStyle({ xys: newAssetTransform });
    }
  };

  useEffect(() => {
    return () => {
      unfocusAsset();
      document.removeEventListener('scroll', unfocusAsset);
      document.removeEventListener('click', unfocusAsset);
    };
  }, [unfocusAsset]);

  return (
    <>
      <ZoomableAssetContainer
        focused={assetFocused ? 'true' : undefined}
        focusable={assetFocusable ? 'true' : undefined}
        onMouseEnter={testAssetFocusable}
        onClick={tryFocusAsset}
        chrome={chrome}
        background={background}
        style={{
          transform: transformStyle.xys.interpolate(
            (x, y, s) => `translate3d(${x}px, ${y}px, 0px) scale(${s})`
          ),
          zIndex,
        }}
      >
        {image && (
          <Picture
            ref={asset}
            src={image}
            alt={captionPlaintext}
            singleSource={singleSource}
            type={type}
          />
        )}
        {video && (
          <Video
            ref={asset}
            src={video}
            hasSound={hasSound}
            hideControls={assetFocused || hideControls}
          />
        )}
        {youTubeVideo && (
          <YouTubeEmbed
            ref={asset}
            videoId={youTubeVideo}
            title={captionPlaintext}
            hasSound={hasSound}
            hideControls={assetFocused || hideControls}
          />
        )}
      </ZoomableAssetContainer>

      {assetFocused && (
        <Helmet>
          <style type="text/css">{`
            body {
              cursor: url(${UnMagnifySVG}) 25 25, zoom-out;
            }
          `}</style>
        </Helmet>
      )}
    </>
  );
};

export default ZoomableAsset;

const ZoomableAssetContainer = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: border-radius 1s ease;

  ${props =>
    props.background &&
    css`
      background: var(--theme-secondary);
    `}

  ${props =>
    props.chrome &&
    css`
      overflow: hidden;
    `}

  ${props =>
    props.chrome &&
    !props.focused &&
    css`
      border-radius: 6px;
    `}

  ${props =>
    props.focusable &&
    css`
      cursor: url(${MagnifySVG}) 24 24, zoom-in;
    `}

  ${props =>
    props.focused &&
    css`
      cursor: url(${UnMagnifySVG}) 25 25, zoom-out;
    `}
`;
