import React from 'react';
import styled, { css } from 'styled-components';
import AssetPlaceholder from './AssetPlaceholder';
import ZoomableAsset from './ZoomableAsset';
import AssetCaption from './AssetCaption';
import AssetPosition from './AssetPosition';

const AssetWithCaption = ({
  children,
  type,
  video,
  youTubeVideo,
  hasSound,
  image,
  height,
  width,
  position,
  singleSource,
  hidePlaceholder,
  disableZoom,
  hideControls,
  browserChrome,
  phoneChrome,
  background,
}) => {
  const captionPlaintext = children && children.toString().trim();

  let chromeAdjustment = '0px';

  if (browserChrome) {
    chromeAdjustment = '12px';
  } else if (phoneChrome) {
    chromeAdjustment = '24px';
  }

  const verticalPadding = `calc(${(height / width) *
    100}% - ${chromeAdjustment})`;

  return (
    <AssetPosition position={position}>
      <AssetContainer
        browserChrome={browserChrome}
        phoneChrome={phoneChrome}
        style={{
          paddingBottom: verticalPadding,
        }}
      >
        {!hidePlaceholder && !(browserChrome || phoneChrome) && (
          <AssetPlaceholder width={width} height={height} />
        )}
        <ZoomableAsset
          type={type}
          captionPlaintext={captionPlaintext}
          video={video}
          youTubeVideo={youTubeVideo}
          hasSound={hasSound}
          image={image}
          singleSource={singleSource}
          disableZoom={disableZoom}
          chrome={browserChrome || phoneChrome ? 'true' : undefined}
          hideControls={hideControls}
          background={background}
        />
      </AssetContainer>

      {children && <AssetCaption position={position}>{children}</AssetCaption>}
    </AssetPosition>
  );
};

export default AssetWithCaption;

const AssetContainer = styled.div`
  height: 0;
  position: relative;

  ${props =>
    props.browserChrome &&
    css`
      box-shadow: 0 0 0px 6px var(--theme-primary),
        0 -12px 0px 6px var(--theme-primary),
        inset 0 0 0 3px var(--theme-primary);
      border-radius: 5px;
      margin: 18px 6px 6px 6px;
    `}

  ${props =>
    props.phoneChrome &&
    css`
      box-shadow: 0 0 0px 6px var(--theme-primary),
        0 12px 0px 6px var(--theme-primary),
        0 -12px 0px 6px var(--theme-primary),
        inset 0 0 0 3px var(--theme-primary);
      border-radius: 5px;
      margin: 18px 6px 18px 6px;
    `}
`;
